import {createElement, forwardRef} from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import {IconCheck, IconChevronRight, IconMinus, IconPoint} from '@tabler/icons-react';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import type {ReactNode} from 'react';
import Paper from '../paper';
import {dropdownMenuItemStyles} from './styles';
import type {Icon} from '../../types';
import type {PaperVariantProps} from '../paper/styles';
import type {DropdownMenuItemVariantProps} from './styles';

export type DropdownMenuTriggerProps = DropdownMenuPrimitive.DropdownMenuTriggerProps;
const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

export type DropdownMenuContentProps = Omit<DropdownMenuPrimitive.DropdownMenuContentProps, 'asChild'> &
	DropdownMenuPrimitive.DropdownMenuPortalProps &
	Omit<PaperVariantProps, 'shadow' | 'padding' | 'bordered'>;
const DropdownMenuContent = forwardRef<HTMLDivElement, DropdownMenuContentProps>(
	({children, ...props}, forwardedRef) => (
		<DropdownMenuPrimitive.Portal>
			<DropdownMenuPrimitive.Content {...props} asChild>
				<Paper
					bordered
					className="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
					padding="xs"
					ref={forwardedRef}
					shadow
				>
					{children}
				</Paper>
			</DropdownMenuPrimitive.Content>
		</DropdownMenuPrimitive.Portal>
	),
);

export type DropdownMenuItemProps = Omit<DropdownMenuPrimitive.DropdownMenuItemProps, 'asChild'> &
	DropdownMenuItemVariantProps & {icon?: Icon; suffix?: ReactNode};
const DropdownMenuItem = forwardRef<HTMLDivElement, DropdownMenuItemProps>(
	({icon, destructive, suffix, children, className, ...props}, forwardedRef) => (
		<DropdownMenuPrimitive.Item
			{...props}
			className={twMerge(dropdownMenuItemStyles({destructive}), className)}
			ref={forwardedRef}
		>
			<div className="flex items-center justify-start gap-1.5">
				{icon && createElement(icon, {className: 'h-4 w-4'})}
				<div className="min-w-0 truncate">{children}</div>
			</div>
			{suffix && <div className="flex shrink-0 flex-nowrap items-center">{suffix}</div>}
		</DropdownMenuPrimitive.Item>
	),
);

export type DropdownMenuCheckboxItemProps = Omit<
	DropdownMenuPrimitive.DropdownMenuCheckboxItemProps,
	'asChild'
> &
	DropdownMenuItemVariantProps & {icon?: Icon};
const DropdownMenuCheckboxItem = forwardRef<HTMLDivElement, DropdownMenuCheckboxItemProps>(
	({icon, destructive, checked, className, children, ...props}, forwardedRef) => (
		<DropdownMenuPrimitive.CheckboxItem
			{...props}
			checked={checked}
			className={twMerge(dropdownMenuItemStyles({destructive}), className)}
			ref={forwardedRef}
		>
			<div className="flex items-center justify-start gap-1.5">
				{icon && createElement(icon, {className: 'h-4 w-4'})}
				<div className="min-w-0 truncate">{children}</div>
			</div>
			<DropdownMenuPrimitive.ItemIndicator>
				{checked === 'indeterminate' && <IconMinus className="h-4 w-4" />}
				{checked === true && <IconCheck className="h-4 w-4" />}
			</DropdownMenuPrimitive.ItemIndicator>
		</DropdownMenuPrimitive.CheckboxItem>
	),
);

export type DropdownMenuRadioGroupProps = DropdownMenuPrimitive.DropdownMenuRadioGroupProps;
const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

export type DropdownMenuRadioItemProps = Omit<DropdownMenuPrimitive.DropdownMenuRadioItemProps, 'asChild'> &
	DropdownMenuItemVariantProps & {icon?: Icon};
const DropdownMenuRadioItem = forwardRef<HTMLDivElement, DropdownMenuRadioItemProps>(
	({icon, destructive, className, children, ...props}, forwardedRef) => (
		<DropdownMenuPrimitive.RadioItem
			{...props}
			className={twMerge(dropdownMenuItemStyles({destructive}), className)}
			ref={forwardedRef}
		>
			<div className="flex items-center justify-start gap-1.5">
				{icon && createElement(icon, {className: 'h-4 w-4'})}
				<div className="min-w-0 truncate">{children}</div>
			</div>
			<DropdownMenuPrimitive.ItemIndicator>
				<IconPoint className="h-4 w-4 fill-current" />
			</DropdownMenuPrimitive.ItemIndicator>
		</DropdownMenuPrimitive.RadioItem>
	),
);

export type DropdownMenuSeparatorProps = DropdownMenuPrimitive.DropdownMenuSeparatorProps;
const DropdownMenuSeparator = forwardRef<HTMLDivElement, DropdownMenuSeparatorProps>(
	({className, ...props}, forwardedRef) => (
		<DropdownMenuPrimitive.Separator
			{...props}
			className={twMerge('my-1 h-[1px] bg-mauve6', className)}
			ref={forwardedRef}
		/>
	),
);

export type DropdownMenuLabelProps = DropdownMenuPrimitive.DropdownMenuLabelProps;
const DropdownMenuLabel = forwardRef<HTMLDivElement, DropdownMenuLabelProps>((props, forwardedRef) => (
	<DropdownMenuPrimitive.Label
		{...props}
		className="select-none px-1.5 py-0.5 font-mono text-xs font-medium text-mauve11"
		ref={forwardedRef}
	/>
));

export type DropdownMenuGroupProps = DropdownMenuPrimitive.DropdownMenuGroupProps;
const DropdownMenuGroup = DropdownMenuPrimitive.Group;

export type DropdownMenuSubProps = DropdownMenuPrimitive.DropdownMenuSubProps;
const DropdownMenuSub = DropdownMenuPrimitive.Sub;

export type DropdownMenuSubTriggerProps = DropdownMenuPrimitive.DropdownMenuSubTriggerProps & {icon?: Icon};
const DropdownMenuSubTrigger = forwardRef<HTMLDivElement, DropdownMenuSubTriggerProps>(
	({icon, children, className, ...props}, forwardedRef) => (
		<DropdownMenuPrimitive.SubTrigger
			{...props}
			className={twMerge(dropdownMenuItemStyles({destructive: false}), className)}
			ref={forwardedRef}
		>
			<div className="flex items-center justify-start gap-1.5">
				{icon && createElement(icon, {className: 'h-4 w-4'})}
				<div className="min-w-0 truncate">{children}</div>
			</div>
			<IconChevronRight className="h-4 w-4" />
		</DropdownMenuPrimitive.SubTrigger>
	),
);

export type DropdownMenuSubContentProps = Omit<DropdownMenuPrimitive.DropdownMenuSubContentProps, 'asChild'> &
	Omit<PaperVariantProps, 'shadow' | 'padding' | 'bordered'>;
const DropdownMenuSubContent = forwardRef<HTMLDivElement, DropdownMenuSubContentProps>(
	({children, ...props}, forwardedRef) => (
		<DropdownMenuPrimitive.Portal>
			<DropdownMenuPrimitive.SubContent {...props} asChild ref={forwardedRef}>
				<Paper
					bordered
					className="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
					padding="xs"
					shadow
				>
					{children}
				</Paper>
			</DropdownMenuPrimitive.SubContent>
		</DropdownMenuPrimitive.Portal>
	),
);

export type DropdownMenuProps = DropdownMenuPrimitive.DropdownMenuProps;
const DropdownMenu = assign((props: DropdownMenuProps) => <DropdownMenuPrimitive.Root {...props} />, {
	Trigger: DropdownMenuTrigger,
	Content: DropdownMenuContent,
	Item: DropdownMenuItem,
	CheckboxItem: DropdownMenuCheckboxItem,
	RadioGroup: DropdownMenuRadioGroup,
	RadioItem: DropdownMenuRadioItem,
	Separator: DropdownMenuSeparator,
	Label: DropdownMenuLabel,
	Group: DropdownMenuGroup,
	Sub: DropdownMenuSub,
	SubTrigger: DropdownMenuSubTrigger,
	SubContent: DropdownMenuSubContent,
});

DropdownMenu.Trigger.displayName = 'DropdownMenu.Trigger';
DropdownMenu.Content.displayName = 'DropdownMenu.Content';
DropdownMenu.Item.displayName = 'DropdownMenu.Item';
DropdownMenu.CheckboxItem.displayName = 'DropdownMenu.CheckboxItem';
DropdownMenu.RadioGroup.displayName = 'DropdownMenu.RadioGroup';
DropdownMenu.RadioItem.displayName = 'DropdownMenu.RadioItem';
DropdownMenu.Separator.displayName = 'DropdownMenu.Separator';
DropdownMenu.Label.displayName = 'DropdownMenu.Label';
DropdownMenu.Group.displayName = 'DropdownMenu.Group';
DropdownMenu.Sub.displayName = 'DropdownMenu.Sub';
DropdownMenu.SubTrigger.displayName = 'DropdownMenu.SubTrigger';
DropdownMenu.SubContent.displayName = 'DropdownMenu.SubContent';

export default DropdownMenu;

export * from './styles';
